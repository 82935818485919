import { Link } from "react-router-dom";
import styles from "./AboutUs.module.scss";

// Icons
import { FaDiscord, FaGithub, FaReact } from "react-icons/fa";
import { FaGolang } from "react-icons/fa6";
import { SiPostgresql, SiSass } from "react-icons/si";
import { GiDragonfly } from "react-icons/gi";
import { TbBrandCSharp } from "react-icons/tb";


export default function AboutUsPage() {

	return (
		<>
			<section className={styles.roadmap}>
				<h1 className={styles.title}>About us</h1>
				
				<div className={styles.content}>
					<h2>The backstory</h2>

					<p>
						SteamSets is a platform created by a group of friends who wanted to create a platform for Steam users to track their statistics in various leaderboards.
						<br />

						We started the project in late 2022 and have been working on it ever since. <br />
					</p>

				</div>

				<div className={styles.content}>
					<h2>Our goals</h2>

					<p>
						Our main goal is to become the go-to platform for Steam users who are interested in their statistics and leaderboards. <br />
						We want to create a platform that is easy to use and has a lot of features our users want. <br />
						If you feel like you could provide valuable feedback or have ideas for the platform, feel free to join our <Link to="https://links.steamsets.com/discord" target="_blank">Discord server</Link> and let us know! <br />
					</p>
				</div>
				<div className={styles.content}>
					<h2>The future</h2>

					<p>
						We have a lot of ideas for the future of SteamSets, and we are excited to see where the platform will go. <br />
						The platform is still in its early stages, and we have a lot of features we want to add and improve on. <br />
						<br />
						Do you think you'd be a good fit for the team? Feel free to reach out to us on Discord! <br />
					</p>
				</div>

				<hr />

				<div className={styles.content}>
					<h2>Behind the platform</h2>

					<div className={styles.tech}>
						<div className={styles.item}>
							<div className={styles.header}>
								<div className={styles.name}>
									<img src="https://avatars.akamai.steamstatic.com/b236dd828c5e2e731eca3b6cfc776ec9e59a3345_full.jpg" alt="zwolof avatar" />									
									<h3>zwolof</h3>
								</div>
								
								<div className={styles.socials}>
									<Link to="https://github.com/zwolof" target="_blank"><FaGithub /> Github</Link>
								</div>
							</div>

							<div className={styles.layout}>
								<div className={styles.list}>
									<div className={styles.question}>
										<h5>What did you do for SteamSets?</h5>
										<p>
											I developed the frontend of the platform, including the user interface and the user experience.
											<br />
											My main focus was to create a platform that is easy to use and has a lot of features our users want.
										</p>
									</div>

									<div className={styles.question}>
										<h5>What were the challenges?</h5>
										<p>
											The biggest challenge was to create a UI that is easy for anyone to use, regardless of their experience with Steam or leaderboards.
											<br />
											I also had to make sure that the platform is easy to navigate and has all the features our users want.
											Choosing the right technologies and tools was also a challenge, I've had to learn a lot of new things to make the platform work as intended.
										</p>
									</div>

									<div className={styles.question}>
										<h5>What did you learn?</h5>
										<p>
											I became more proficient in React and learned a lot about user experience and user interface design.
										</p>
									</div>
								</div>

								<ul className={styles.stack}>
									<li><FaReact /> React</li>
									<li><TbBrandCSharp /> C#</li>
									<li><SiSass /> SCSS</li>
								</ul>
							</div>
						</div>
						<div className={styles.item}>
							<div className={styles.header}>
								<div className={styles.name}>
									<img src="https://cdn.discordapp.com/avatars/347497307777925121/9edbdac3c9a3e92fd426180e267081e3.webp?size=80" alt="flo avatar" />
									<h3>Flo</h3>
								</div>
								
								<div className={styles.socials}>
									<Link to="https://github.com/Flo4604" target="_blank"><FaGithub /> Github</Link>
								</div>
							</div>

							<div className={styles.layout}>
								<div className={styles.list}>
									<div className={styles.question}>
										<h5>What did you do for SteamSets?</h5>
										<p>
											I gathered all the necessary data which made it possible for the website to exist.
											<br />
											Also, I designed the database and implemented the API.											
										</p>
									</div>

									<div className={styles.question}>
										<h5>What were the challenges?</h5>
										<ol>
											<li>Getting zwolof to respond in a respectable timeframe</li>
											<li>
												Making the backend somewhat performant, we store a lot of cascading data in a complex database schema which can cause things to be slow and size to be huge, with just a few accounts being stored unless done properly.
												We utilize caching in a heavy manner to make sure our various APIs can respond in a timely manner. The tricky part is cache invalidation due to us having multiple sources with tons of data with tons of various keys.
												The database structure is very complex, without a proper structure things can get very messy, very quickly.
											</li>
											<li>Structuring and managing all the leaderboards as they are all subdivided based on region, country, state and city. We also support leaderboards for each appId where any user has a playtime greater than x, you do the math.</li>
										</ol>
										<p>
										</p>
									</div>

									<div className={styles.question}>
										<h5>What did you learn?</h5>
										<p>
											I learned Golang from scratch, I had no prior experience with the language.
											<br />
											While working with a queue system, I learned a lot about the potential pitfalls and how to avoid them.
										</p>
									</div>
								</div>

								<ul className={styles.stack}>
									<li><FaGolang /> Golang</li>
									<li><GiDragonfly /> DragonflyDB</li>
									<li><SiPostgresql /> PostgreSQL</li>
								</ul>
							</div>
						</div>

						<div className={styles.item}>
							<div className={styles.header}>
								<div className={styles.name}>
									<img src="https://avatars.akamai.steamstatic.com/ce26fe6c71200b60e3ce352ebe0b73d86ed711f0_full.jpg" alt="flo avatar" />
									<h3>Labrador</h3>
								</div>
								
								<div className={styles.socials}>
									<Link to="https://github.com/labradorjman" target="_blank">
										<FaGithub /> Github
									</Link>
								</div>
							</div>

							<div className={styles.layout}>
								<div className={styles.list}>
									<div className={styles.question}>
										<h5>What did you do for SteamSets?</h5>
										<p>
											I've developed a Discord bot to help the community better connect with the services offered by SteamSets. <br />
											The bot features a ticketing system that facilitates smooth communication between community members and staff.<br />
											Additionally, it includes a badge tagging system that allows users to assign tags to badges, enabling other community members to search for desired badges.
											Currently, the badge tagging system is being developed into a web application to enhance the user experience for tagging and viewing badges.
										</p>
									</div>

									<div className={styles.question}>
										<h5>What were the challenges?</h5>
										
										<p>
											The challenges I faced while working on the project included understanding and using tools I had not previously worked with, particularly those related to the automation of updating and deploying the Discord bot.
											Other things like understanding and using docker for the first time and becoming efficient in prototyping UX designs for the Discord bot.
										</p>
									</div>

									<div className={styles.question}>
										<h5>What did you learn?</h5>
										<p>
											Creating the bot provided me with valuable experience in using dependency injection and attributes in C#,
											which were essential for connecting to various services and ensuring the code's flexibility for future development.
											Additionally, working with SteamSets exposed me to a range of commonly used development tools such as Redis and Docker. <br />
											Throughout the project, I gained valuable experience working with databases, learning about Entity Framework and various database practices.
											Overall, working with SteamSets significantly improved my skills as a developer in every aspect.
										</p>
									</div>
								</div>

								<ul className={styles.stack}>
									<li><FaDiscord /> Discord API</li>
									<li><TbBrandCSharp /> C#</li>
									<li><SiPostgresql /> PostgreSQL</li>
								</ul>
							</div>
						</div>

						{/* <div className={styles.item}>
							<h3>Labrador</h3>

							<div className={styles.layout}>
								<div className={styles.list}>
									<div className={styles.question}>
										<h5>What did you do for SteamSets?</h5>
										<p>aaa</p>
									</div>

									<div className={styles.question}>
										<h5>What were the challenges?</h5>
										<p>aaa</p>
									</div>

									<div className={styles.question}>
										<h5>What did you learn?</h5>
										<p>aaa</p>
									</div>
								</div>

								<ul className={styles.tech}>
									<li>React</li>
									<li>Node.js</li>
									<li>PostgreSQL</li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>
			</section>
		</>
	)
}